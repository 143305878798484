import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timi-mulitpleworkbreakform-container inline" }
const _hoisted_2 = {
  key: 0,
  class: "timi-multipleworkbreakform-content"
}
const _hoisted_3 = { class: "form-layout" }
const _hoisted_4 = { class: "timi-form-item col-6" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "form-footer justify-flexend" }
const _hoisted_11 = {
  key: 0,
  class: "form-footer form-footer--error"
}
const _hoisted_12 = { class: "form-error" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "row has-padding-small justify-flexend" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_UiTimeInput = _resolveComponent("UiTimeInput")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.workbreaks.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form, (fEntry) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["row has-actions", { 'has-error': fEntry.invalid }]),
                key: fEntry.uid
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("form", {
                    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmitPrevent && _ctx.onSubmitPrevent(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('label.start')) + " ", 1),
                        (fEntry.invalid)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "svgicon svgicon--attention",
                              innerHTML: _ctx.icons.attention
                            }, null, 8, _hoisted_5))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_DateInput, {
                          value: fEntry.startDay,
                          onOnChange: (v) => _ctx.onDateChanged(fEntry, 'start', v)
                        }, null, 8, ["value", "onOnChange"]),
                        _createVNode(_component_UiTimeInput, {
                          value: fEntry.startTime,
                          onOnChange: (v) => _ctx.onTimeChanged(fEntry, 'start', v)
                        }, null, 8, ["value", "onOnChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('label.stop')) + " ", 1),
                        (fEntry.invalid)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "svgicon svgicon--attention",
                              innerHTML: _ctx.icons.attention
                            }, null, 8, _hoisted_8))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_DateInput, {
                          value: fEntry.stopDay,
                          onOnChange: (v) => _ctx.onDateChanged(fEntry, 'stop', v)
                        }, null, 8, ["value", "onOnChange"]),
                        _createVNode(_component_UiTimeInput, {
                          value: fEntry.stopTime,
                          onOnChange: (v) => _ctx.onTimeChanged(fEntry, 'stop', v)
                        }, null, 8, ["value", "onOnChange"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_IconButton, {
                        icon: _ctx.icons.clearDate,
                        secondary: true,
                        onOnClick: () => _ctx.onClearStop(fEntry)
                      }, null, 8, ["icon", "onOnClick"]),
                      _createVNode(_component_IconButton, {
                        icon: _ctx.store.getters.svgIcons.delete,
                        secondary: true,
                        onOnClick: () => _ctx.onDelete(fEntry)
                      }, null, 8, ["icon", "onOnClick"]),
                      _createVNode(_component_IconButton, {
                        icon: _ctx.icons.undo,
                        secondary: true,
                        onOnClick: ($event: any) => (_ctx.onCancel(fEntry)),
                        disabled: !fEntry.changed
                      }, null, 8, ["icon", "onOnClick", "disabled"]),
                      _createVNode(_component_IconButton, {
                        icon: fEntry.invalid ? _ctx.icons.attention : _ctx.icons.save,
                        secondary: true,
                        onOnClick: () => _ctx.onSave(fEntry),
                        disabled: !fEntry.changed || fEntry.invalid
                      }, null, 8, ["icon", "onOnClick", "disabled"])
                    ]),
                    false
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.icons.undo,
                            secondary: true,
                            onOnClick: ($event: any) => (_ctx.onCancel(fEntry)),
                            disabled: !fEntry.changed
                          }, null, 8, ["icon", "onOnClick", "disabled"]),
                          _createElementVNode("span", _hoisted_12, [
                            _createElementVNode("i", {
                              class: "svgicon svgicon--attention",
                              innerHTML: _ctx.icons.attention
                            }, null, 8, _hoisted_13)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 32)
                ])
              ], 2))
            }), 128)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_Button, {
                text: _ctx.$t('button.close'),
                raised: true,
                primary: true,
                onOnClick: _ctx.onClose
              }, null, 8, ["text", "onOnClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "svgicon svgicon--loader",
            innerHTML: _ctx.store.getters.svgIcons.loader
          }, null, 8, _hoisted_15))
    ])
  ]))
}