
import store from '@/store/index';
import { defineComponent } from 'vue';
import { IconButton } from '@/ui/index';
import { TimeEntry } from '@/model';
import { Converter, DateTimeUtils } from "@/utils";
export default defineComponent({
    name: "TimeEntryCard",
    components: { IconButton },
    props: {
        entry: {
            type: Object, 
            default: null
        },
        mode: {
            type: String,
            default: 'user'
        }
    },
    data(){
        return{
            store           
        }
    },
    computed: {
        workingTime() {
            return this.entry.getTimeGone()
            /* if(this.entry.stop > 0 ){
                return this.convertTimeStamp(this.entry.stop - this.entry.start)
            } else {
                return this.convertTimeStamp(this.entry.tstamp - this.entry.start)
            } */
        },
        projectTitle() {
            const p = this.entry.project
            let title = p.numberExternal ? p.numberExternal : ''
            title += p.numberInternal ? (title ? ' / ' : '') + p.numberInternal : ''
            title += p.numberSerial ? (title ? ' / ' : '') + p.numberSerial : ''
            title += p.title ? (title ? ' ' : '') + p.title : ''
            return title
        }
    },
    methods: {
        convertTimeStamp(seconds:number){
            return Converter.convertSecondsToTime(seconds)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },
        onEditTimeEntry(entry: TimeEntry) {
            this.$emit('edit', entry)
        },
        onDeleteTimeEntry(entry: TimeEntry) {         
            this.$emit('delete', entry)
        }
    }
})
