import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-uid"]
const _hoisted_2 = { class: "timeentry-text" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "timeentry-time" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "timeentry-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["timi-timeentry-card", { 'mode-admin': _ctx.mode == 'admin'}]),
    "data-uid": _ctx.entry.uid
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.entry.work.title), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.projectTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.entry.editTime)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.convertSeconds(_ctx.entry.editTime)), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", {
        class: _normalizeClass({ 'canceled ' : _ctx.entry.editTime})
      }, _toDisplayString(_ctx.convertSeconds(_ctx.workingTime)), 3)
    ]),
    (_ctx.mode == 'admin')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_IconButton, {
            icon: _ctx.store.getters.svgIcons.pencil,
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEditTimeEntry(_ctx.entry)))
          }, null, 8, ["icon"]),
          _createVNode(_component_IconButton, {
            icon: _ctx.store.getters.svgIcons.delete,
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDeleteTimeEntry(_ctx.entry)))
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}