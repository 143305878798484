
import { defineComponent } from 'vue'
import { PresenceController } from '@/controller/'
import { UiTimeInput, DateInput, Button, IconButton } from '@/ui/index'
import { Presence } from '@/model'
import { DateTimeUtils } from '@/utils'
import store from '@/store/index'

export default defineComponent({
    name: "MultiplePresenceForm",
    components: { UiTimeInput, DateInput, Button, IconButton },
    props: {
        presences: {
            type: Array,
            default: [] as Presence[]
        }
    },    
    data(){
        return{
            store,
            form: [] as any[],
            timeChanged: false,
            icons: {
                undo: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><g><path d="M3.1,4.1c1.3-1.3,3-2.1,4.9-2.1,3.9,0,7,3.1,7,7s-.5,3-1.4,4.2c-1.3,1.7-3.3,2.8-5.6,2.8-3.9,0-7-3.1-7-7" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/><line x1="2.1" y1="5" x2="3.1" y2="4.1" fill="none" stroke="#1b1b1b" stroke-miterlimit="10"/><polyline points="2.1 2.5 2.1 5 4.6 5" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10"/></g></svg>',
                save: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <circle cx="8" cy="9" r="7" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>        <polyline points="11.2 7.1 7.4 10.9 4.8 8.4" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>  </g></svg>',
                attention: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">  <g>        <circle cx="8" cy="9" r="7.2" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>        <line x1="8" y1="10.6" x2="8" y2="4.8" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>        <line x1="8" y1="12.6" x2="8" y2="12.6" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".9"/>  </g></svg>',
                clearDate: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" version="1.1" viewBox="0 0 16 18">      <g>        <path d="M1.3,13.9c0,.7.5,1.2,1.2,1.2h10.9c.7,0,1.2-.5,1.2-1.2V4.1H1.3v9.7Z" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="9.2" y1="5.4" x2="9.2" y2="2.9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="6.8" y1="5.4" x2="6.8" y2="2.9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="11.6" y1="5.4" x2="11.6" y2="2.9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="4.4" y1="5.4" x2="4.4" y2="2.9" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="6.2" y1="12" x2="9.8" y2="8.4" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>        <line x1="6.2" y1="8.4" x2="9.8" y2="12" fill="none" stroke="#1b1b1b" stroke-linecap="square" stroke-miterlimit="10" stroke-width=".8"/>      </g></svg>'
            },
            changesSaved: false
        }
    },
    watch: {
        presences() {
            console.log('watch presences', this.presences)
        }
    },
    mounted() {
        this.setFormValues()
    },
    methods: {
        formatDateForInput(ts: number) {
            return ts ? DateTimeUtils.formatDateForInput(new Date(ts*1000)) : ''
        },
        getTimeFromTS(ts: number) {
            return ts ? DateTimeUtils.getTimeOnly(new Date(ts*1000)) : 0
        },
        onCancel(formEntry: any) {
            const presence = this.presences.find((p: any) => formEntry.uid == p.uid)
            const replaceEntry = {}
            this.setFormEntryValues(replaceEntry, presence)
            const idx = this.form.findIndex((p:any) => formEntry.uid == p.uid)
            if (idx != -1) this.form.splice(idx, 1, replaceEntry) 
        },
        onClose() {
            this.$emit('close', this.changesSaved)
        },
        onSubmitPrevent() {
            //this.updateWorkbreak()
        },
        onDateChanged(formEntry: any, property: string, value: any) {
                //string Datum
            const d = DateTimeUtils.getDateOnly(new Date(value)) + DateTimeUtils.getTimeOnly(new Date((property == 'come' ? formEntry.come : formEntry.go)*1000));
            if (property == 'come') {
                formEntry.come = d
                formEntry.comeDay = this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((formEntry.come)*1000)))
                formEntry.comeTime = this.getTimeFromTS(d)
            } else {
                formEntry.go = d
                formEntry.goDay = this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((formEntry.go)*1000)))
                formEntry.goTime = this.getTimeFromTS(d)
            }
            formEntry.changed = true
            formEntry.invalid = formEntry.come > formEntry.go && formEntry.go != 0
        },        
        onTimeChanged(formEntry: any, property: string, value: any) {
            let d = DateTimeUtils.getDateOnly(new Date((property == 'come' ? formEntry.come : formEntry.come)*1000)) + value.sec
            if (property == 'come') {
                formEntry.come = d
                formEntry.comeDay = this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((formEntry.come)*1000)))
                formEntry.comeTime = this.getTimeFromTS(d)
            } else {
                if (d < formEntry.come) d = formEntry.come
                formEntry.go = d
                formEntry.goDay = (formEntry.go < formEntry.come) ? this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((formEntry.come)*1000))) : this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((formEntry.go)*1000)))
                formEntry.goTime = this.getTimeFromTS(d)
            }
            formEntry.changed = true
            formEntry.invalid = formEntry.come > formEntry.go && formEntry.go != 0 && formEntry.goDay != ''
        },        
        onClearGo(formEntry: any) {
            formEntry.go = 0
            formEntry.goDay = ''
            formEntry.goTime = 0
            formEntry.changed = true
            formEntry.invalid = formEntry.come > formEntry.go && formEntry.go != 0 && formEntry.goDay != ''
        },
        /* onTimeChanged(formEntry: any, property: string, value: any) {
            //const d = DateTimeUtils.getDateOnly(new Date((property == 'come' ? this.form.come : this.form.go)*1000)) + value.sec
            if (property == 'start') {
                formEntry.start = this.startDay + value.sec
            } else {
                formEntry.stop = this.stopDay +  value.sec
            }
            this.timeChanged = true
        }, */
        onSave(formEntry: any) {
            this.updatePresence(formEntry)
        },
        onDelete(formEntry: any) {
            console.log('onDelete formEntry', formEntry)
            this.$confirm(this.$t("confirm.delete"), this.$t('confirm.deletePresenceText'), {
                labelConfirm: this.$t("button.delete"),
                onConfirm: () => { this.deletePresence(formEntry) },
                //onCancel: () => { console.log("onCancel"); }
            });            
        },
        setFormValues() {
            this.presences.forEach((p: any) => {
                const entry = {}
                this.setFormEntryValues(entry, p)
                this.form.push(entry)
            })
        },
        setFormEntryValues(formEntry: any, presence: any) {
            formEntry.uid = presence.uid,
            formEntry.employee = presence.employee,
            formEntry.come = presence.come,
            formEntry.go = presence.go,
            formEntry.comeTime = this.getTimeFromTS(presence.come),
            formEntry.goTime = this.getTimeFromTS(presence.go),
            formEntry.comeWorkstation = presence.comeWorkstation,
            formEntry.goWorkstation = presence.goWorkstation,
            formEntry.comeDay = this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((presence.come)*1000))),
            formEntry.goDay = this.formatDateForInput(DateTimeUtils.getDateOnly(new Date((presence.go)*1000))),
            formEntry.changed = false
            formEntry.invalid = formEntry.come > formEntry.go && formEntry.go != 0
        },
        async updatePresence(formEntry: any) {
                //send ms to server
            const result = await PresenceController.updatePresence(
                formEntry.uid, 
                formEntry.employee, 
                formEntry.come,
                formEntry.go,
                formEntry.comeWorkstation, 
                formEntry.goWorkstation
            )
            if (result?.uid) {
                //console.log('handleUpdateTimeEntry', result)
                //this.$router.go(-1) 
                formEntry.changed = false
                this.changesSaved = true
                this.$emit('update', result)
            } else {
                //show erro message
            }
        },
        async deletePresence(formEntry: any) {
            const result = await PresenceController.deletePresence(formEntry.uid)
            if (result?.uid) {
                const idx = this.form.findIndex((entry: any) => entry.uid == formEntry.uid)
                if (idx!=-1) this.form.splice(idx,1)
                this.$emit('delete', result)
                this.changesSaved = true
            }
        }
    },
})
