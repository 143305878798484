
import { defineComponent } from 'vue';
import { WorkController, ProjectController, TimeEntryController } from '@/controller/';
import { UiTimeInput, Button, SelectSingleSearch } from '@/ui/index';
import { User, Project, Employee, Work, TimeEntry } from '@/model';
import store from '@/store/index';

export default defineComponent({
    name: "TimeEntryForm",
    components: { UiTimeInput, Button, SelectSingleSearch },
    props: {
        entry: {
            type: TimeEntry, 
            default: null
        }
    },    
    data(){
        return{
            store,
            projects: [] as Array<Project>,
            //clients: [] as Array<any>,
            works: [] as Array<Work>,
            timeEntry: {} as TimeEntry,
            form:{
                projectId: 0,
                customerId: 0,
                workId: 0,
                workstationId: 0,
                employeeId: 0,
                hours: 0,
                minutes: 0,
                time: 0
            },
            searchInput:'',
            activeTab: 0,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            timeChanged: false
        }
    },
    computed:{
        filteredProjectList() {
            if(this.projects) {
                let projectList : any = [...this.projects]

                if(this.searchInput != ""){
                    projectList = projectList.filter((item:any) => {
                        if(item.title.toLowerCase().includes(this.searchInput.toLowerCase())) {
                            return item
                        }
                    })
                }
                return projectList
            }
            return []
        },
        filteredWorkList(){
            if (this.works) {
                
                let workList : any = this.works

                if(this.searchInput != ""){
                    workList = workList.filter((item:any)=>{
                        if(item.title.toLowerCase().includes(this.searchInput.toLowerCase())){
                            return item
                        }
                    })
                }
                return workList
            }
            return []
        },
        selectedWork() : Work | undefined {
            const works = (this.works) ? this.works : undefined
            if (works) {
                return works.find((w:Work) => w.uid == this.form.workId)
            } else {
                return undefined
            }
        },
        selectedProject(): Project | undefined {
            const projects = this.projects ? this.projects : null
            if (projects) {
                return projects.find((p:Project) => p.uid == this.form.projectId)
            } else {
                return undefined
            }
        }
    },
    mounted() {
        console.log('mounted', this.entry)
        this.timeEntry = this.entry
        this.form.employeeId = this.timeEntry.employee.uid
            
        this.getProjects()
        this.getWorks()
    },
    methods: {
        onCancel() {
            this.$emit('cancel')
        },
        /* handleSelectProject(item:any) {
            this.form.projectId = item.uid
            this.form.customerId = item.customer.uid
        },
        handleSelectWork(item:any) {
            this.form.workId = item.uid
        }, */
        onSubmitPrevent() {
            this.updateTimeEntry()
        },
        onTimeChanged(property: string, value: any) {
            //console.log('handleTimeChanged', value)
            this.form.time = value?.sec
            this.timeChanged = true
        },
        onSave() {
            this.updateTimeEntry()
        },
        onWorkChange(item: any, value:number, text:string) {
            console.log('handleWorkChange', item, value, text)
            this.form.workId = value
        },  
        onProjectChange(item: any, value:number, text:string) {
            this.form.projectId = value
        },
        setFormValues() {
            //console.log('this.timeEntry', this.timeEntry);
            this.form.projectId = this.timeEntry.project?.uid
            this.form.customerId = this.timeEntry.customer?.uid
            this.form.workId = this.timeEntry.work?.uid
            this.form.employeeId = this.timeEntry.employee.uid
            if (this.timeEntry.editTime != 0) {
                this.form.time = this.timeEntry.editTime
            } else {
                this.form.time = this.timeEntry.getTimeTracked()
            }
        },
        checkDataLoaded() {
            if (this.projects.length && this.works.length) {
                this.setFormValues()
            }
        },
        async getProjects() {
            this.projects = (await ProjectController.fetchProjects()).items
            this.checkDataLoaded()
        },
        async getWorks() {
            const workItems = await WorkController.fetchWorks()
            if (workItems) {
                this.works = workItems.items
            }
            this.checkDataLoaded()
        },
        async updateTimeEntry() {
                //send ms to server
            const result = await TimeEntryController.updateTimeEntry(this.timeEntry.uid,  this.form.time, this.timeChanged, this.timeEntry.tstamp, this.form.employeeId, this.form.workId, this.form.projectId, this.form.customerId, this.form.workstationId)
            if (result?.uid) {
                //console.log('handleUpdateTimeEntry', result)
                //this.$router.go(-1) 
                this.$emit('update', result)
            } else {
                //show erro message
            }
        },
    },
})
