import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "timi-timeentryform-container inline" }
const _hoisted_2 = {
  key: 0,
  class: "timi-timeentryform-content"
}
const _hoisted_3 = { class: "form-layout" }
const _hoisted_4 = { class: "timi-form-item" }
const _hoisted_5 = { class: "timi-form-item" }
const _hoisted_6 = { class: "timi-form-item" }
const _hoisted_7 = { class: "form-footer justify-flexend" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTimeInput = _resolveComponent("UiTimeInput")!
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ((_ctx.works?.length && _ctx.projects?.length))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmitPrevent && _ctx.onSubmitPrevent(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.time')), 1),
                  _createVNode(_component_UiTimeInput, {
                    value: _ctx.form.time,
                    onOnChange: _cache[0] || (_cache[0] = (v) => _ctx.onTimeChanged('time', v))
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_SelectSingleSearch, {
                    placeholder: _ctx.$t('placeholder.selectWork'),
                    item: { label: _ctx.$t('label.work'), name: 'editTimeEntrySelectWork' },
                    options: _ctx.works,
                    labelProperty: "title",
                    value: { value: _ctx.form.workId, text: _ctx.selectedWork?.title },
                    allowCreateNew: false,
                    emptyStateText: _ctx.$t('emptyState.works'),
                    onOnChange: _ctx.onWorkChange
                  }, null, 8, ["placeholder", "item", "options", "value", "emptyStateText", "onOnChange"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_SelectSingleSearch, {
                    placeholder: _ctx.$t('placeholder.selectProject'),
                    item: { label: _ctx.$t('label.project'), name: 'editTimeEntrySelectProject' },
                    options: _ctx.projects,
                    labelProperty: "title",
                    value: { value: _ctx.form.projectId, text: _ctx.selectedProject?.title },
                    allowCreateNew: false,
                    emptyStateText: _ctx.$t('emptyState.projects'),
                    onOnChange: _ctx.onProjectChange
                  }, null, 8, ["placeholder", "item", "options", "value", "emptyStateText", "onOnChange"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Button, {
                    text: _ctx.$t('button.cancel'),
                    secondary: true,
                    onOnClick: _ctx.onCancel
                  }, null, 8, ["text", "onOnClick"]),
                  _createVNode(_component_Button, {
                    text: _ctx.$t('button.save'),
                    primary: true,
                    raised: true,
                    onOnClick: _ctx.onSave
                  }, null, 8, ["text", "onOnClick"])
                ])
              ], 32)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "svgicon svgicon--loader",
            innerHTML: _ctx.store.getters.svgIcons.loader
          }, null, 8, _hoisted_8))
    ])
  ]))
}