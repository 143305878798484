import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "listitem__delete-action swipe swipe--left" }
const _hoisted_3 = { class: "listitem__content swipe--component" }
const _hoisted_4 = { class: "listitem__detail" }
const _hoisted_5 = {
  key: 0,
  class: "listitem__toggle-startstop"
}
const _hoisted_6 = {
  key: 1,
  class: "listitem__toggle-startstop"
}
const _hoisted_7 = {
  key: 2,
  class: "listitem__toggle-startstop"
}
const _hoisted_8 = { class: "listitem__edit-action swipe swipe--right" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "timeentry-list-empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!

  return (!_ctx.dataLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "svgicon svgicon--loader",
        innerHTML: _ctx.store.getters.svgIcons.loader
      }, null, 8, _hoisted_1))
    : (_ctx.timeEntries.length > 0 )
      ? (_openBlock(), _createBlock(_component_List, {
          key: 1,
          class: "ww-list-timeentries"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeEntries, (timeEntry) => {
              return (_openBlock(), _createBlock(_component_ListItem, {
                key: (timeEntry as TimeEntry).uid,
                swipeLeft: _ctx.allowSwipe,
                swipeRight: _ctx.allowSwipe,
                resetSwipe: _ctx.resetSwipe,
                readOnly: _ctx.readOnly,
                onOnSwipeLeft: ($event: any) => (_ctx.onTimeEntrySwipeLeft(timeEntry)),
                onOnSwipeRight: ($event: any) => (_ctx.onTimeEntrySwipeRight(timeEntry))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_IconButton, {
                      icon: _ctx.store.getters.svgIcons.delete,
                      label: _ctx.$t('button.delete'),
                      onOnClick: ($event: any) => (_ctx.onDeleteTimeEntry(timeEntry))
                    }, null, 8, ["icon", "label", "onOnClick"])
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.getProjectTitle(timeEntry)), 1),
                      _createElementVNode("p", null, _toDisplayString(((timeEntry as TimeEntry).work ? (timeEntry as TimeEntry).work.title : '')), 1)
                    ]),
                    ((timeEntry as TimeEntry).uid == _ctx.activeTimeEntry.uid)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.pause,
                            disabled: _ctx.readOnly,
                            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggleTimeEntry(_ctx.activeTimeEntry, 'stop'))),
                            label: _ctx.timeTracked
                          }, null, 8, ["icon", "disabled", "label"])
                        ]))
                      : ((timeEntry as TimeEntry).editTime)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_IconButton, {
                              icon: _ctx.store.getters.svgIcons.playplus,
                              disabled: _ctx.readOnly,
                              onOnClick: ($event: any) => (_ctx.onToggleTimeEntry(timeEntry, 'start')),
                              label: _ctx.convertSeconds((timeEntry as TimeEntry).editTime)
                            }, null, 8, ["icon", "disabled", "onOnClick", "label"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_IconButton, {
                              icon: _ctx.store.getters.svgIcons.play,
                              disabled: _ctx.readOnly,
                              onOnClick: ($event: any) => (_ctx.onToggleTimeEntry(timeEntry, 'start')),
                              label: _ctx.convertSeconds((timeEntry as TimeEntry).timeGone)
                            }, null, 8, ["icon", "disabled", "onOnClick", "label"])
                          ]))
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_IconButton, {
                      icon: _ctx.store.getters.svgIcons.pencil,
                      label: _ctx.$t('button.edit'),
                      onOnClick: ($event: any) => (_ctx.onEditTimeEntry(timeEntry))
                    }, null, 8, ["icon", "label", "onOnClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["swipeLeft", "swipeRight", "resetSwipe", "readOnly", "onOnSwipeLeft", "onOnSwipeRight"]))
            }), 128))
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('emptyState.noActivitiesToday')), 1)
        ]))
}